import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './card.css'
const Card = ({ path, name }) => {
    return (
        <div>
            <Box className='box' position={'relative'}>
                <CardMedia
                    component={'img'}
                    style={img}
                    src={path}
                />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <div className='overCard' >
                        <Typography>
                            {name}
                        </Typography>
                    </div>
                </Box>
            </Box>
        </div>
    )
}
const img = {
    width: '250px',
    height: '150px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',
    objectFit: 'cover',
    margin: 'auto'
}
export default Card
