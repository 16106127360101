import { Box, Button, Container, Grid } from '@mui/material'
import React from 'react'
import Title from './../glopal/Title'
import Card from './Card'
import { Link } from 'react-router-dom'
import D5 from './../../assets/Enterances_Gates/002.jpg'
import D6 from './../../assets/Kiosks/003.jpg'
import D4 from './../../assets/Columns ACP_ Awnings/001.jpg'
import D7 from './../../assets/Car Vinyl/004.jpg'
import D10 from './../../assets/Stand/006.JPG'
import D9 from './../../assets/decor/005.jpg'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
const OurServices = () => {
    const {t} = useTranslation()
    let lng = i18next.language
    return (
        <Box sx={{ padding: { md: '0 0 40px 0', xs: '10px' } }} >
            <Container>
                <Box>
                    <Title title={t("linksPages.OurServices")} />
                    <Grid container spacing={2} >
                        <Grid item md={4} xs={12} >
                            <Card path={D5} name={t("services.Entrances&Gates")} route={'/Entrances-Gates'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D4} name={lng === 'ar' ? 'تلبيس واكساء الاعمدة والمظلات  بالكلادينج الالمنيوم المقاوم للحريق' :'ACP Colomns & Awnings'
                                // t("services.ACPColomns&Awnings")
                                } route={'/ACP-Colomns'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D6} name={lng === 'ar' ? 'اكشاك القهوة والمطاعم السريعة و المتنقله' : 'Kiosks'
                                // t("services.Kiosks")
                                } route={'/Kiosks'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D7} name={t("services.CarVinyl")} route={'/Car-Vinyl'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D10} name={lng === 'ar' ? 'استنادات عرض بخيارات متنوهعة' :'Stand'
                                // t("services.Stand")
                                } route={'/Stand'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D9} name={t("services.decor")} route={'/decor'} />
                        </Grid>
                    </Grid>
                    <Box sx={{ direction: 'rtl' }} >
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#951A1D', color: '#FFF', gap: 2, fontSize: '1rem',
                                borderRadius: '12px', m: 2, ':hover': {
                                    backgroundColor: '#951A1D'
                                }
                            }} >
                            <Link
                                to="/Services"
                                style={{
                                    color: "#fff",
                                    textDecoration: "none",
                                    // fontSize: 18,
                                    fontWeight: "bold",
                                }}>
                                {t("ViewAll")}
                            </Link>
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default OurServices
