import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

export default function NavDrawer({ setDrawer, drawer }) {
    return (
        <div>
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                        }}>
                        <Link
                            to="/"
                            style={{
                                color: "inherit",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            Home
                        </Link>
                        <Link
                            to="/Channel-Letters"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Channel Letters
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/Hoarding-Signs"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Hoarding Signs
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/ACP-Wood"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            ACP & Wood Cladding
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/Various-Project"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Various Project
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/Entrances-Gates"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Entrances & Gates
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/ACP-Colomns"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            ACP Colomns & Awnings
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/Kiosks"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Kiosks
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/Car-Vinyl"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Car Vinyl
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/Stand"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Stand
                        </Link>
                        {/* <EditIcon /> */}
                        <Link
                            to="/decor"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                            onClick={() => setDrawer(false)}
                        >
                            Decor
                        </Link>
                        <Link
                            to="/Sectors"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>
                            Sectors
                        </Link>
                        <Link
                            to="/about-us"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>
                            About
                        </Link>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
