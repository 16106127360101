import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import Card from '../glopal/Card'
import D4 from './../../assets/ListCard/6.jpg'
import D5 from './../../assets/ListCard/3.jpg'
import D6 from './../../assets/ListCard/4.jpg'
import D7 from './../../assets/ListCard/7.jpg'

const ListCard = () => {
    return (
        <div>
            <Box sx={{ display: { md: 'flex', xs: 'none' } }} position={'relative'} >
                <Container sx={{ position: 'absolute', top: '-100px' }} >
                    <Grid container >
                        <Grid item md={3} xs={12} >
                            <Card path={D4} />
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <Card path={D5} />
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <Card path={D6} />
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <Card path={D7} />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}

export default ListCard
