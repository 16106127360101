import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, CardMedia, Container, Grid } from '@mui/material'
import D4 from './../assets/Columns ACP_ Awnings/001.jpg'
import D5 from './../assets/Columns ACP_ Awnings/002.jpg'
import D6 from './../assets/Columns ACP_ Awnings/003.jpg'
import D7 from './../assets/Columns ACP_ Awnings/004.jpg'
import D9 from './../assets/Columns ACP_ Awnings/005.jpg'
import D14 from './../assets/Columns ACP_ Awnings/009.jpg'
import D15 from './../assets/Columns ACP_ Awnings/010.jpg'
import D16 from './../assets/Columns ACP_ Awnings/011.jpg'
import D17 from './../assets/Columns ACP_ Awnings/IMG_20181128_173043.jpg'
import { useTranslation } from 'react-i18next'

const ACPColomns = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Header2 title={t("services.ACPColomns&Awnings")} />
            <Container sx={{ padding: '50px 0' }} >
                <Box className='scl' >
                    <Grid container spacing={4} >
                        <Grid item md={3} xs={12} >
                            <a href={D4} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D4}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D5} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D5}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D6} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D6}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D7} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D7}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D9} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D9}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D14} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D14}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D15} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D15}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D16} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D16}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D17} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D17}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',
    height: '250px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',
    objectFit: 'fill',
    margin: 'auto',

}
export default ACPColomns
