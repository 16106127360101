import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Information = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Box >
                <Typography sx={{ fontWeight: 'bold', color: '#951A1D' }} variant='h6' >{t("Information")}</Typography>
                <Box
                    sx={{
                        gap: 2,
                        display: "flex",
                        flexDirection: { md: "column", xs: 'row' },
                        flexWrap: 'wrap',
                        textAlign: "left",
                        padding: "20px",
                    }}>
                    <Link
                        to="/"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            fontSize: 18,
                            width: 'fit-content'
                        }}>
                        {t("linksPages.Home")}
                    </Link>
                    <Link
                        to="/Services"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            fontSize: 18,
                            width: 'fit-content'
                        }}>
                        {t("services.Services")}
                    </Link>
                    <Link
                        to="/Sectors"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            fontSize: 18,
                            width: 'fit-content'
                        }}>
                        {t("linksPages.Sectors")}
                    </Link>
                    <Link
                        to="/partners"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            fontSize: 18,
                            width: 'fit-content'
                            // fontWeight: "bold",
                        }}>
                        {t("linksPages.Partners")}
                    </Link>
                    <Link
                        to="/about-us"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            fontSize: 18,
                            width: 'fit-content'
                        }}>
                        {t("linksPages.About")}
                    </Link>
                    <Link
                        to="/contact-us"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            fontSize: 18,
                            width: 'fit-content'
                        }}>
                        {t("linksPages.ContactUs")}
                    </Link>
                </Box>
            </Box>
        </div>
    )
}

export default Information
