import React from 'react'
import Title from '../glopal/Title'
import SliderLogic from '../SliderLogic/SliderLogic'
import { Box, Button } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const OurPartners = ({ image_1, image_3, image_4, image_5, image_6, image_7, image_9, image_10, image_12, image_13, image_14, image_15, image_16 }) => {
    const {t} = useTranslation()
    return (
        <div>
            <Title title={t("OurPartner")} />
            <SliderLogic
                image_1={image_1}
                image_3={image_3}
                image_4={image_4}
                image_5={image_5}
                image_6={image_6}
                image_7={image_7}
                image_9={image_9}
                image_10={image_10}
                image_12={image_12}
                image_13={image_13}
                image_14={image_14}
                image_15={image_15}
                image_16={image_16}
            />
            <Box sx={{ direction: 'rtl' }} >
                <Button variant="contained"
                    sx={{
                        backgroundColor: '#951A1D', color: '#FFF', gap: 2, fontSize: '1rem',
                        borderRadius: '12px', m: 2, ':hover': {
                            backgroundColor: '#951A1D'
                        }
                    }} >
                    <Link
                        to="/partners"
                        style={{
                            color: "#fff",
                            textDecoration: "none",
                            // fontSize: 18,
                            fontWeight: "bold",
                        }}>
                        {t('ViewAll')}
                    </Link>
                </Button>
            </Box>
        </div>
    )
}

export default OurPartners
