import { Box, Typography } from '@mui/material'
import React from 'react'

const Header2 = ({ title, desc }) => {
    return (
        <div>
            <div className='header'>
                <div className="overlay"></div>
                <Box sx={{ textAlign:'center' }} >
                    <Typography sx={{
                        color: "#FFF",
                        fontWeight: 'bold',
                        zIndex: 9,
                        margin: 'auto',
                        textAlign: 'center',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center',
                        // fontSize: '2.75rem'
                        my:2
                    }} variant='h3' >
                        {title}
                    </Typography>
                    <Typography sx={{
                        color: "#FFF",
                        fontWeight: 'bold',
                        zIndex: 9,
                        margin: 'auto',
                        textAlign: 'center',
                        position: 'relative',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        mx: { md: 10 ,xs:1}
                    }}>
                        {desc}
                    </Typography>
                    {/* {children} */}
                </Box>
            </div>
        </div>
    )
}

export default Header2
