import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import Title from '../glopal/Title'
import { useTranslation } from 'react-i18next'

const OurSectors = () => {
    const { t } = useTranslation()
    return (
        <div style={{ padding: '0 0 25px 0' }} >
            <Container>
                <Box sx={{ textAlign: { md: 'center', xs: 'left' } }} >
                    <Title title={t("Sectors.title")} />
                    <Typography sx={{ fontSize: '20px' }} >
                        {t("Sectors.line1")}<br />
                        {t("Sectors.line2")}<br />
                        {t("Sectors.line3")}
                    </Typography>
                </Box>
            </Container>
        </div>
    )
}

export default OurSectors
