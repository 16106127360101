import { Box, Button, TextField } from '@mui/material'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';

const Form = () => {
    const {t} = useTranslation()
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_3xthfa7', 'template_mxam0gu', form.current, '2qSDpir2aV7Ni-7xc')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    return (
        <div>
            <Box sx={{ boxShadow: ' 0px 4px 30px 0px rgba(0, 0, 0, 0.25)', p: 3, borderRadius: '30px' }}>
                <Box component={'form'} ref={form} onSubmit={sendEmail} >
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }} >
                        <Box sx={{ width: { md: '100%', xs: '100%' }, my: 2 }} >
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder={t("form.Name")}
                                id="name"
                                variant="filled"
                                size="medium"
                                name='name'
                            />
                        </Box>
                        <Box sx={{ width: { md: '100%', xs: '100%' }, my: 2 }}  >
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder={t("form.E-mail")}
                                id="email"
                                variant="filled"
                                size="medium"
                                name='email'
                            />
                        </Box>
                        <Box sx={{ width: { md: '100%', xs: '100%' }, my: 2 }}  >
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder={t("form.Phone")}
                                id="Phone"
                                variant="filled"
                                size="medium"
                                name='phone'
                            />
                        </Box>
                        <Box sx={{ width: { md: '100%', xs: '100%' }, my: 2 }}  >
                            <TextField
                                fullWidth
                                hiddenLabel
                                placeholder={t("form.YourMessage")}
                                id="Message"
                                variant="filled"
                                size="medium"
                                name='message'
                            />
                        </Box>
                    </Box>
                    <Box textAlign={'center'} >
                        <Button variant="contained"
                            type="submit"
                            fullWidth
                            sx={{
                                backgroundColor: '#951A1D', ':hover': {
                                    backgroundColor: '#951A1D'
                                },
                                color: "#FFF",
                                fontWeight: 'bold'
                                // margin: 3
                            }}>{t("form.Send")}</Button>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}
export default Form
