import React from 'react'
import Header from '../components/Header/Header'
import AboutUs from '../components/AboutUs/AboutUs'
import OurServices from '../components/ourService/OurServices'
import OurSectors from '../components/Our Sectors/OurSectors'
import ContactUs from '../components/Contact/ContactUs'
import OurPartners from '../components/Partners/OurPartners'
import D1 from './../assets/partners/AKEL logo.png'
import D3 from './../assets/partners/Aljabr Finance.png'
import D4 from './../assets/partners/boncafee.png'
import D5 from './../assets/partners/fireflay logo.png'
import D6 from './../assets/partners/future care logo.png'
import D7 from './../assets/partners/jamil hospital.png'
import D9 from './../assets/partners/KIA-aljabrpng.png'
import D10 from './../assets/partners/Petro Rabi  copy copy.png'
import D12 from './../assets/partners/Pizza Hut logo 2022-1.png'
import D13 from './../assets/partners/Royal_Saudi_Land_Forces.png'
import D14 from './../assets/partners/saja logo-1.png'
import D15 from './../assets/partners/Sukoon IEC.png'
import D16 from './../assets/partners/tibyana.png'
import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const Home = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Header />
            <AboutUs />
            <OurServices />
            <OurSectors />
            <OurPartners
                image_1={D1}
                image_3={D3}
                image_4={D4}
                image_5={D5}
                image_6={D6}
                image_7={D7}
                image_9={D9}
                image_10={D10}
                image_12={D12}
                image_13={D13}
                image_14={D14}
                image_15={D15}
                image_16={D16}
            />
            <ContactUs />
            <Box sx={{ height: '200px', background: 'linear-gradient(-90deg, rgba(180, 227, 245, 1) 28%, rgba(88, 139, 202, 1) 100%)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '25px' }} >
                <Typography sx={{ fontWeight: 'bold', }} variant='h4' >{t("topFooter.LitsWorkTogether")}</Typography>
                <Link
                    to="/contact-us"
                    style={{
                        color: "#FFF",
                        textDecoration: "none",
                        fontSize: 18,
                        fontWeight: "bold",
                    }}
                >
                    <Button variant="contained"
                        type="button"
                        sx={{
                            backgroundColor: '#951A1D', ':hover': {
                                backgroundColor: '#951A1D'
                            },
                            color: "#FFF",
                            fontWeight: 'bold'
                        }}>{t("topFooter.GetInTouch")}</Button>
                </Link>
            </Box>
        </div>
    )
}

export default Home
