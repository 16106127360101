import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'

import D1 from './../assets/Channel Letters/00000000000000.jpg'
import D3 from './../assets/Channel Letters/00000000000000000.jpg'
import D4 from './../assets/Channel Letters/001.jpg'
import D5 from './../assets/Channel Letters/002.jpg'
import D6 from './../assets/Channel Letters/003.jpg'
import D7 from './../assets/Channel Letters/004.jpg'
import D9 from './../assets/Channel Letters/005.jpg'
import D10 from './../assets/Channel Letters/006.jpg'
import D12 from './../assets/Channel Letters/007.jpg'
import D13 from './../assets/Channel Letters/008.jpg'
import D14 from './../assets/Channel Letters/009.jpg'
import D15 from './../assets/Channel Letters/010.jpg'
import D16 from './../assets/Channel Letters/011.jpg'
import D17 from './../assets/Channel Letters/012.jpg'
import D18 from './../assets/Channel Letters/013.jpg'
import D19 from './../assets/Channel Letters/014.jpg'
import D20 from './../assets/Channel Letters/015.jpg'
import D21 from './../assets/Channel Letters/016.jpg'
import D22 from './../assets/Channel Letters/017.jpg'
import D23 from './../assets/Channel Letters/018.jpg'
import D24 from './../assets/Channel Letters/019.jpg'
import D25 from './../assets/Channel Letters/020.jpg'
import D26 from './../assets/Channel Letters/021.png'
import D27 from './../assets/Channel Letters/022.jpg'
import D28 from './../assets/Channel Letters/023.jpg'
import { useTranslation } from 'react-i18next'

const ChannelLetters = () => {
    const {t} = useTranslation()
    return (
        <div>
            <div>
                <div className='header_ChannelLetters'>
                    <div className="overlay"></div>
                    <Box sx={{ textAlign: 'center' }} >
                        <Typography sx={{
                            color: "#FFF",
                            fontWeight: 'bold',
                            zIndex: 9,
                            margin: 'auto',
                            textAlign: 'center',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            justifyContent: 'center',
                            // fontSize: '2.75rem'
                            my: 2
                        }} variant='h3' >
                            {t("services.ChannelLetters")}
                        </Typography>
                        {/* {children} */}
                    </Box>
                </div>
            </div>
            {/* <Header2 title={t("services.ChannelLetters")} /> */}
            <Container sx={{ padding: '50px 0' }} >
                <Box className='scl' >
                    <Grid container spacing={4} >
                        <Grid item md={3} xs={12} >
                            <a href={D1} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D1}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D3} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D3}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D4} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D4}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D5} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D5}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D6} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D6}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D7} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D7}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D9} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D9}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D10} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D10}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D12} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D12}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D13} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D13}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D14} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D14}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D15} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D15}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D16} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D16}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D17} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D17}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D18} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D18}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D19} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D19}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D20} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D20}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D21} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D21}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D22} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D22}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D23} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D23}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D24} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D24}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D25} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D25}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D26} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D26}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D27} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D27}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D28} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D28}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',
    height: '250px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',
    objectFit: 'fill',
    margin: 'auto',

}
export default ChannelLetters
