import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, Grid, Typography } from '@mui/material'
import Card from '../components/ourService/Card'
import D5 from './../assets/Enterances_Gates/002.jpg'
import D6 from './../assets/Kiosks/003.jpg'
import D4 from './../assets/Columns ACP_ Awnings/001.jpg'
import D7 from './../assets/Car Vinyl/004.jpg'
import D10 from './../assets/Stand/006.JPG'
import D9 from './../assets/decor/005.jpg'
import D28 from './../assets/Channel Letters/023.jpg'
import D27 from './../assets/Interfaces/022.jpg'
import D11 from './../assets/Hoarding Signs/002.jpg'
import D12 from './../assets/Various Projects/005.jpg'
import D21 from './../assets/ACPWood/016.jpg'
import { useTranslation } from 'react-i18next'
const Services = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Header2 title={t("linksPages.OurServices")} />
            <Container sx={{ padding: { md: '50px 0', xs: '25px' } }} >
                <Box>
                    <Typography sx={{ pb: '25px' }} >
                        {t("services.desc")}
                    </Typography>
                    <Grid container spacing={4} >
                        <Grid item md={4} xs={12} >
                            <Card path={D28} name= {t("services.ChannelLetters")} route={'/Channel-Letters'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D27} name= {t("services.Interfaces")} route={'/Interfaces'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D11} name= {t("services.HoardingSigns")} route={'/Hoarding-Signs'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D21} name= {t("services.ACP&WoodCladding")} route={'/ACP-Wood'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D12} name={t("services.VariousProject")} route={'/Various-Project'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D5} name={t("services.Entrances&Gates")} route={'/Entrances-Gates'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D4}  name={t("services.ACPColomns&Awnings")} route={'/ACP-Colomns'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D6} name={t("services.Kiosks")} route={'/Kiosks'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D7} name={t("services.CarVinyl")} route={'/Car-Vinyl'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D10} name={t("services.Stand")} route={'/Stand'} />
                        </Grid>
                        <Grid item md={4} xs={12} >
                            <Card path={D9} name={t("services.decor")} route={'/decor'} />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default Services
