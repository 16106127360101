import React, { useEffect, useMemo, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import NavBar from "./components/NavBar";
import Footer from "./components/Footer/Footer";
import ScrollTo from './components/Ui/ScrollToTop';
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import OurSectors from "./components/Our Sectors/OurSectors";
import Partners from "./pages/Partners";
import ChannelLetters from "./pages/ChannelLetters";
import Interfaces from "./pages/Interfaces";
import HoardingSigns from "./pages/HoardingSigns";
import ACPWood from "./pages/ACPWood";
import VariousProject from "./pages/VariousProject";
import EntrancesGates from "./pages/EntrancesGates";
import ACPColomns from "./pages/ACPColomns";
import Kiosks from "./pages/Kiosks";
import CarVinyl from "./pages/CarVinyl";
import Stand from "./pages/Stand";
import Maintenance from "./pages/Maintenance";
import { CardMedia } from "@mui/material";
import R from './assets/logo/logo.svg';

const languages = [
  {
    code: 'ar',
    name: 'Ar',
    country_code: 'sa',
    dir: 'rtl'
  },
  {
    code: 'en',
    name: 'En',
    country_code: 'gb',
    dir: 'ltr'
  }
]

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500); // تأخير لمدة 3 ثواني

    return () => clearTimeout(timer);
  }, []);

  const currentLanguageCode = Cookies.get('i18next') || 'ar';
  const currentLanguage = useMemo(() => languages.find(l => l.code === currentLanguageCode), [currentLanguageCode])
  const { t } = useTranslation();

  useEffect(() => {
    document.dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('lang', currentLanguage.code || 'ar');
  }, [currentLanguage, t]);

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  const { pathname } = useLocation();
  useEffect(() => {
    setLoading(true); // تشغيل اللودينج عندما يتغير العنوان URL

    const handleLoaded = () => setLoading(false);

    window.addEventListener('load', handleLoaded);

    // إيقاف اللودينج بعد مرور ثانيتين
    const timeout = setTimeout(() => {
      setLoading(false);
      window.removeEventListener('load', handleLoaded);
    }, 1000);

    return () => {
      clearTimeout(timeout); // إذا تم التراجع عن تغيير الصفحة، قم بإلغاء المهمة المؤجلة
    };
  }, [pathname]);


  return (
    <div className="App">
      <CssBaseline />
      <NavBar />
      <ScrollToTop />
      <ScrollTo />
      {loading ? (
        <div className="loading-page">
          <div className='loading-animation'></div>
        </div>

      ) : (
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<About />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Sectors" element={<OurSectors />} />
            <Route path="/Channel-Letters" element={<ChannelLetters />} />
            <Route path="/Interfaces" element={<Interfaces />} />
            <Route path="/Hoarding-Signs" element={<HoardingSigns />} />
            <Route path="/ACP-Wood" element={<ACPWood />} />
            <Route path="/Various-Project" element={<VariousProject />} />
            <Route path="/Entrances-Gates" element={<EntrancesGates />} />
            <Route path="/ACP-Colomns" element={<ACPColomns />} />
            <Route path="/Kiosks" element={<Kiosks />} />
            <Route path="/Car-Vinyl" element={<CarVinyl />} />
            <Route path="/Stand" element={<Stand />} />
            <Route path="/decor" element={<Maintenance />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/contact-us" element={<Contact />} />
          </Routes>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
