import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Link } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomizedMenus() {
    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{
                    color: '#000', fontWeight: 'bold', backgroundColor: 'transparent',
                    ':hover': { backgroundColor: 'transparent' },
                }}>
                <Link
                    to="/Services"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                        // fontSize: 18,
                        fontWeight: "bold",
                    }}>
                    {t("linksPages.OurServices")}
                </Link>
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {/* <EditIcon /> */}
                <Link
                    to="/Channel-Letters"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                        {t("services.ChannelLetters")}
                    </MenuItem>
                </Link>
                <Link
                    to="/Interfaces"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}>
                    <MenuItem onClick={handleClose} disableRipple>
                        {t("services.Interfaces")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/Hoarding-Signs"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                       {t("services.HoardingSigns")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/ACP-Wood"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                       {t("services.ACP&WoodCladding")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/Various-Project"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                        {t("services.VariousProject")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/Entrances-Gates"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                        {t("services.Entrances&Gates")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/ACP-Colomns"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                       {t("services.ACPColomns&Awnings")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/Kiosks"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                       {t("services.Kiosks")}
                    </MenuItem>
                </Link>
                <Divider sx={{ my: 0.5 }} />
                {/* <EditIcon /> */}
                <Link
                    to="/Car-Vinyl"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                       {t("services.CarVinyl")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/Stand"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                        {t("services.Stand")}
                    </MenuItem>
                </Link>
                {/* <EditIcon /> */}
                <Link
                    to="/decor"
                    style={{
                        color: "#000",
                        textDecoration: "none",
                    }}
                >
                    <MenuItem onClick={handleClose} disableRipple>
                        {t("services.decor")}
                    </MenuItem>
                </Link>
            </StyledMenu>
        </div>
    );
}