import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'
import D4 from './../assets/Interfaces/001.jpg'
import D5 from './../assets/Interfaces/002.jpg'
import D6 from './../assets/Interfaces/003.jpg'
import D7 from './../assets/Interfaces/004.jpg'
import D9 from './../assets/Interfaces/005.jpg'
import D10 from './../assets/Interfaces/006.jpg'
import D12 from './../assets/Interfaces/007.jpg'
import D13 from './../assets/Interfaces/008.jpg'
import D14 from './../assets/Interfaces/009.jpg'
import D15 from './../assets/Interfaces/010.jpg'
import D16 from './../assets/Interfaces/011.jpg'
import D17 from './../assets/Interfaces/012.jpg'
import D18 from './../assets/Interfaces/013.jpg'
import D19 from './../assets/Interfaces/014.jpg'
import D20 from './../assets/Interfaces/015.jpg'
import D21 from './../assets/Interfaces/016.jpg'
import D22 from './../assets/Interfaces/017.jpg'
import D23 from './../assets/Interfaces/018.jpg'
import D24 from './../assets/Interfaces/019.jpg'
import D25 from './../assets/Interfaces/020.jpg'
import D26 from './../assets/Interfaces/021.jpg'
import D27 from './../assets/Interfaces/022.jpg'
import D28 from './../assets/Interfaces/023.jpg'
import D29 from './../assets/Interfaces/0024.jpg'
import D30 from './../assets/Interfaces/025.jpg'
import D31 from './../assets/Interfaces/0026.jpg'
import D32 from './../assets/Interfaces/0027.jpg'
import D33 from './../assets/Interfaces/0028.jpg'
import D34 from './../assets/Interfaces/0029.jpg'
import D35 from './../assets/Interfaces/0030.jpg'
import D36 from './../assets/Interfaces/0031.jpg'
import D37 from './../assets/Interfaces/0032.jpg'
import { useTranslation } from 'react-i18next'

const Interfaces = () => {
    const { t } = useTranslation()
    return (
        <div>
            <div>
                <div className='header_Interfaces'>
                    <div className="overlay"></div>
                    <Box sx={{ textAlign: 'center' }} >
                        <Typography sx={{
                            color: "#FFF",
                            fontWeight: 'bold',
                            zIndex: 9,
                            margin: 'auto',
                            textAlign: 'center',
                            position: 'relative',
                            display: 'flex',
                            alignItems: 'center',
                            height: '100%',
                            justifyContent: 'center',
                            // fontSize: '2.75rem'
                            my: 2
                        }} variant='h3' >
                            {t("services.Interfaces")}
                        </Typography>
                        {/* {children} */}
                    </Box>
                </div>
            </div>
            {/* <Header2 title={t("services.Interfaces")} /> */}
            <Container sx={{ padding: '50px 0' }} >
                <Box className='scl' >
                    <Grid container spacing={4} >
                        <Grid item md={3} xs={12} >
                            <a href={D4} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D4}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D5} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D5}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D6} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D6}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D7} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D7}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D9} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D9}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D10} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D10}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D12} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D12}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D13} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D13}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D14} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D14}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D15} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D15}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D16} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D16}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D17} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D17}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D18} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D18}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D19} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D19}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D20} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D20}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D21} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D21}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D22} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D22}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D23} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D23}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D24} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D24}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D25} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D25}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D26} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D26}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D27} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D27}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D28} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D28}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D29} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D29}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D30} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D30}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D31} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D31}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D32} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D32}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D33} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D33}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D34} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D34}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D35} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D35}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D36} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D36}
                                />
                            </a>
                        </Grid>

                        <Grid item md={3} xs={12} >
                            <a href={D37} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D37}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}
const img = {
    width: '100%',
    height: '250px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',
    objectFit: 'fill',
    margin: 'auto',

}

export default Interfaces
