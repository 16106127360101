import * as React from 'react';
import PublicIcon from '@mui/icons-material/Public';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
export default function BasicSelect() {
    const lngs = {
        en: { nativeName: 'English' },
        ar: { nativeName: 'Arabic' }
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const { i18n } = useTranslation()
    return (
        <div>
            <Box textAlign={'end'} >
                <PublicIcon
                    sx={{ width: { md: 35, xs: 30 }, height: { md: 35, xs: 30 }, cursor: "pointer", color: '#000' }}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                />
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}>
                    {Object.keys(lngs).map((lng) => (
                        <MenuItem key={lng}
                            style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal', zIndex: 99999999999999999999999999999999999999999999999999999, color: "#000" }}
                            type="submit"
                            value={lng}
                            onClick={() => { i18n.changeLanguage(lng); handleClose() }}>{lngs[lng].nativeName}</MenuItem>
                    ))}
                </Menu>
            </Box>
        </div>
    );
}