import { Typography } from '@mui/material'
import React from 'react'

const Title = ({ title }) => {
    return (
        <div>
            <Typography sx={{ color: '#951A1D', textAlign: 'center', fontWeight: 'bold', m: '15px 0' }} variant='h4' >
                {title}
            </Typography>
        </div>
    )
}

export default Title
