import { useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import logo from './../../assets/logo/logo.svg'
import './Nav.css'
import CustomizedMenus from "./CustomizedMenus";
import BasicSelect from "./TransLang";
import { useTranslation } from "react-i18next";


function NavBar() {
    const [drawer, setDrawer] = useState(false);
    const {t} = useTranslation()
    return (
        <>
            <AppBar position="static" elevation={0}
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    // background: 'rgb(180, 227, 245)',
                    background: 'linear-gradient(90deg, rgba(180, 227, 245, 1) 28%, rgba(88, 139, 202, 1) 100%)',
                    padding: "10px"
                }}>
                <>
                    <Toolbar disableGutters sx={{ justifyContent: 'space-between' }} >

                        <Link
                            to="/"
                            style={{
                                color: "#000",
                                textDecoration: "none",
                            }}
                        >
                            <img src={logo} height={50} alt="logo" className="nav-logo" ></img>
                        </Link>
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 2,
                                marginInlineStart: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'flex-start'
                            }}>
                            <Box sx={{
                                flexGrow: 1,
                                gap: 2,
                                marginInlineStart: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'space-evenly',
                            }}>
                                <Link
                                    to="/"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    {t("linksPages.Home")}
                                </Link>
                                <CustomizedMenus />
                                <Link
                                    to="/Sectors"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    {t("linksPages.Sectors")}
                                </Link>
                                <Link
                                    to="/partners"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    {t("linksPages.Partners")}
                                </Link>
                                <Link
                                    to="/about-us"
                                    style={{
                                        color: "#000",
                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    {t("linksPages.About")}
                                </Link>
                                <BasicSelect />
                            </Box>
                            <Link
                                to="/contact-us"
                                style={{
                                    color: "#FFF",
                                    textDecoration: "none",
                                    fontSize: 18,
                                    fontWeight: "bold",
                                }}
                            >
                                <Button variant="contained"
                                    sx={{
                                        backgroundColor: '#951A1D', color: '#FFF', gap: 2, fontSize: '1rem',
                                        borderRadius: '12px', ':hover': {
                                            backgroundColor: '#951A1D'
                                        }
                                    }} >
                                    {t("linksPages.ContactUs")}
                                </Button>
                            </Link>
                        </Box>
                        <Box sx={{
                            justifyContent: 'end', display: 'flex', gap: { md: '65px', xs: 1 },
                            alignItems: 'center'
                        }} >
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "end",
                                }}>
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ fontSize: '2rem', color: '#000' }} />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
