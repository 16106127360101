import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
// import { footerLinks } from '../../constants'
import { Link } from 'react-router-dom'
const PathPages = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Box>
                <Typography sx={{ fontWeight: 'bold', color: '#951A1D' }} variant='h6' >{t("services.Services")}</Typography>
                <Box
                    sx={{

                        gap: 2,
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: 'column',
                        // textAlign: "left",
                        padding: "20px",
                    }}>
                    <Link
                        to="/Channel-Letters"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.ChannelLetters")} ,
                    </Link>
                    <Link
                        to="/Interfaces"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.Interfaces")} ,
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/Hoarding-Signs"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.HoardingSigns")} ,
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/ACP-Wood"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.ACP&WoodCladding")},
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/Various-Project"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.VariousProject")} ,
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/Entrances-Gates"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.Entrances&Gates")},
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/ACP-Colomns"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.ACPColomns&Awnings")},
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/Kiosks"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.Kiosks")} ,
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/Car-Vinyl"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.CarVinyl")},
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/Stand"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.Stand")} ,
                    </Link>
                    {/* <EditIcon /> */}
                    <Link
                        to="/decor"
                        style={{
                            color: "#000",
                            textDecoration: "none",
                            width: 'fit-content'
                        }}

                    >
                        {t("services.decor")} ,
                    </Link>
                </Box>
            </Box>
        </div>
    )
}

export default PathPages
