import React from 'react'
import Box from "@mui/material/Box";
import { Typography } from '@mui/material';
import './Header.css'
import ListCard from './ListCard';
import Typed from 'typed.js';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
const Header = () => {
    const { t } = useTranslation()
    let lng = i18next.language
    // const stringsArray = lng === 'ar' ? ['واجهات', 'الأعمال', 'المشاريع', 'المركبات', 'المعارض', 'المطاعم', 'المقاهي']
    //     : ['Interfaces', 'Business', 'Projects', 'Vehicles', 'Showrooms', 'Restaurants', 'Cafes'];
    // Create reference to store the DOM element containing the animation
    const el = React.useRef(null);
    React.useEffect(() => {
        const typed = new Typed(el.current, {
            strings: lng === 'en' ? ['Interfaces', 'Business', 'Projects', 'Vehicles', 'Showrooms', 'Restaurants', 'Cafes'] : ['واجهات', 'الأعمال', 'المشاريع', 'المركبات', 'المعارض', 'المطاعم', 'المقاهي'],
            typeSpeed: 100,
            loop: true
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, [lng]);
    return (
        <>
            <div className='header'>
                <div className="overlay"></div>
                <Box>
                    <Typography sx={{
                        color: "#FFF",
                        fontWeight: 'bold',
                        zIndex: 9,
                        margin: 'auto',
                        textAlign: 'center',
                        position: 'relative',
                        fontSize: { md: '35px', xs: '20px' }
                    }} variant='h2' >
                        {t("header.ACE-Signs")}
                    </Typography>
                    <Typography sx={{
                        color: "#FFF",
                        fontWeight: 'bold',
                        zIndex: 9,
                        margin: 'auto',
                        textAlign: 'center',
                        position: 'relative',
                        fontSize: { md: '35px', xs: '20px' }
                    }} variant='h2' >
                        {t("header.Premium_Design_Manufacture")} <br /> {t("header.To_serve_the_sectors_of")} <span style={{
                            padding: '8px',
                            backgroundColor: '#b5080a',
                            borderRadius: '16px',
                            display: 'inline-block',
                            width: '250px'
                        }} ><span ref={el} /></span>
                    </Typography>
                </Box>
            </div>
            <Box>
                <ListCard />
            </Box>
        </>
    )
}

export default Header
