import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const DownFooter = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Box p={2} >
                <Typography color={'#FFF'} textAlign={'center'} fontWeight={'bold'} >
                    {t("copyR")}
                    <a href="https://revampbrands.com/" target="_blank" rel="noopener noreferrer"
                        style={{ color: '#fff', textDecoration: 'none' }} >Revamp</a>
                </Typography>
            </Box>
        </div>
    )
}

export default DownFooter
