import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, CardMedia, Container, Grid, Typography } from '@mui/material'

import D1 from './../assets/partners/AKEL logo.png'
import D3 from './../assets/partners/Aljabr Finance.png'
import D4 from './../assets/partners/boncafee.png'
import D5 from './../assets/partners/fireflay logo.png'
import D6 from './../assets/partners/future care logo.png'
import D7 from './../assets/partners/jamil hospital.png'
import D9 from './../assets/partners/KIA-aljabrpng.png'
import D10 from './../assets/partners/Petro Rabi  copy copy.png'
import D12 from './../assets/partners/Pizza Hut logo 2022-1.png'
import D13 from './../assets/partners/Royal_Saudi_Land_Forces.png'
import D14 from './../assets/partners/saja logo-1.png'
import D15 from './../assets/partners/Sukoon IEC.png'
import D16 from './../assets/partners/tibyana.png'
import { useTranslation } from 'react-i18next'
const Partners = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Header2 title={t("OurPartner")} />
            <Container sx={{ padding: { md: '50px 0', xs: '25px' } }} >
                <Box>
                    <Typography sx={{ pb: '25px' }} >
                        {t("ourPartners.descP")}
                    </Typography>
                    <Grid container spacing={4} >
                        <Grid item md={3} xs={12} >
                            <a href={D1} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D1}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D3} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D3}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D4} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D4}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D5} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D5}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D6} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D6}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D7} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D7}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D9} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D9}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D10} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D10}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D12} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D12}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D13} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D13}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D14} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D14}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D15} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D15}
                                />
                            </a>
                        </Grid>
                        <Grid item md={3} xs={12} >
                            <a href={D16} target='_blank' rel="noreferrer" >
                                <CardMedia
                                    component={'img'}
                                    style={img}
                                    src={D16}
                                />
                            </a>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

const img = {
    width: '100%',
    height: '250px',
    borderRadius: '12px',
    backgroundSize: 'cover',
    boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',
    objectFit: 'fill',
    margin: 'auto',

}
export default Partners
