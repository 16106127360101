import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import ContactData from './ContactData'
import Form from './Form'
import Title from '../glopal/Title'
import { useTranslation } from 'react-i18next'

const ContactUs = () => {
    const { t } = useTranslation()
    return (
        <div id='Join_Us' style={{ padding: '50px 0' }}  >
            <Container>
                <Box >
                    <Grid container spacing={2} >
                        <Grid item md={12} xs={12}>
                            <Box textAlign={'center'} >
                                <Title title={t("ContactUs.title")} />
                                <Typography fontSize={'20px'} >
                                    {t("ContactUs.desc")}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={8} xs={12}>
                            <Form />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <ContactData />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </div>
    )
}

export default ContactUs
