import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, Typography } from '@mui/material'
import Form from '../components/Contact/Form'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';

const Contact = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Header2 title={t("ContactUs.title")} />
            <Container sx={{ padding: '50px' }} >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }} >
                    <Typography textAlign={'center'} variant='h5'>
                        {t("ContactUs.desc")}
                    </Typography>
                    <Box>
                        <Box display={'flex'} sx={{ flexDirection: { md: 'row', xs: 'column' }, justifyContent: { md: 'space-between', xs: 'center' }, alignItems: { md: 'center', xs: 'start' } }}  >
                            <Box sx={{ display: { md: 'unset', xs: 'flex' }, gap: 1 }} >
                                <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.Phone")}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                                    <LocalPhoneOutlinedIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                                    <a href="tel:00966565843205" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} ><Typography >00966565843205</Typography></a>
                                </Box>
                            </Box>
                            <Box sx={{ display: { md: 'unset', xs: 'flex' }, gap: 1 }} >
                                <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.Phone")}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                                    <LocalPhoneOutlinedIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                                    <a href="tel:00966599938122" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} ><Typography >00966599938122</Typography></a>
                                </Box>
                            </Box>
                            <Box sx={{ display: { md: 'unset', xs: 'flex' }, gap: 1 }} >
                                <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.FixedPhone")}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                                    <LocalPhoneOutlinedIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                                    <a href="tel:00966114090889" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} ><Typography >00966114090889</Typography></a>
                                </Box>
                            </Box>
                            <Box sx={{ display: { md: 'unset', xs: 'flex' }, gap: 1 }} >
                                <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.E-mail")}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                                    <EmailIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                                    <a href="mailto:essam08@acesigns.net"
                                        style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }}>
                                        <Typography>essam08@acesigns.net</Typography>
                                    </a>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box><Form /></Box>
                </Box>
            </Container>
        </div>
    )
}
export default Contact
