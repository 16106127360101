import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import theme from "./util/theme";
import { SnackbarProvider } from "notistack";
import './locales/i18n';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        style={{ fontSize: '1.5rem' }}
        variant="success"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </BrowserRouter>
);