import { Box, Typography } from '@mui/material'
import React from 'react'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';

const ContactData = () => {
    const { t } = useTranslation()
    return (
        <div>
            <Box style={boxData}>
                <Box display={'flex'} flexDirection={'column'} gap={2} >
                    <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.Phone")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <LocalPhoneOutlinedIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                        <a href="tel:00966599938122" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} ><Typography >00966599938122</Typography></a>
                    </Box>
                    <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.Phone")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <LocalPhoneOutlinedIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                        <a href="tel:00966565843205" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} ><Typography >00966565843205</Typography></a>
                    </Box>
                    <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.FixedPhone")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <LocalPhoneOutlinedIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                        <a href="tel:00966114090889" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} ><Typography >00966114090889</Typography></a>
                    </Box>
                    <Typography sx={{ color: '#3D4EA0', fontWeight: 'bold' }} >{t("form.E-mail")}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }} >
                        <EmailIcon sx={{ fontSize: '1.5rem', color: '#951A1D' }} />
                        <a href="mailto:essam08@acesigns.net" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} >
                            <Typography>essam08@acesigns.net</Typography>
                        </a>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}
const boxData = {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    position: 'relative',
    borderRadius: '30px'

}
export default ContactData
