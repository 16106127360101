import { CardMedia, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import Title from '../glopal/Title'
import path from './../../assets/AboutUs/9.jpg'
import { useTranslation } from 'react-i18next'
const AboutUs = () => {
    const {t} = useTranslation()
    return (
        <div style={{ padding: '40px 0 0 0' }} >
            <Container>
                <Title title={t("aboutUs.title")} />
                <Grid container alignItems={'center'} >
                    <Grid item md={6} xs={12} >
                        <Typography sx={{ lineHeight: { md: '2.4', xs: '1.8' }, fontSize: '20px' }} >
                            {t("aboutUs.line1")}<br /> {t("aboutUs.line2")}<br />
                            {t("aboutUs.line3")}
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12} >
                        <CardMedia
                            component={'img'}
                            style={img}
                            src={path}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
const img = {
    borderRadius: '12px',
    backgroundSize: 'cover',
    boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',
    objectFit: 'cover'
}
export default AboutUs
