import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const ContactData = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Box>
                <Typography sx={{ fontWeight: 'bold', color: '#951A1D' }} variant='h6' >{t("linksPages.ContactUs")}</Typography>
                <Box
                    sx={{
                        gap: 2,
                        display: "flex",
                        flexDirection: "column",
                        // textAlign: "left",
                        padding: "20px",
                    }}>
                        <a href="tel:00966599938122" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} >
                            <Typography >{t("form.Phone")}</Typography><Typography >00966599938122</Typography>
                        </a>
                    <a href="tel:00966565843205" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} >
                        <Typography >{t("form.Phone")}</Typography><Typography >00966565843205</Typography>
                    </a>
                    <a href="tel:00966114090889" style={{ color: '#000', fontWeight: 'bold', textDecoration: 'none' }} >
                        <Typography >{t("form.FixedPhone")}</Typography> <Typography >00966114090889</Typography>
                    </a>
                </Box>
            </Box>
        </div>
    )
}

export default ContactData
