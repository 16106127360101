import { Box, CardMedia, Container, Divider, Grid } from '@mui/material'
import React from 'react'
// import CardLogo from './CardLogo'
import PathPages from './PathPages'
import ContactData from './ContactData'
import DownFooter from './DownFooter'
import Information from './Information'
import logo from './../../assets/logo/logo.svg'

const Footer = () => {
    return (
        <div>
            <Box sx={{ background: 'linear-gradient(180deg, rgba(180, 227, 245, 1) 28%, rgba(88, 139, 202, 1) 100%)', }} >
                <Container>
                    <Grid container spacing={2} sx={{ alignItems: 'flex-start', justifyContent: 'center' }} >
                        <Grid item md={3} xs={12}>
                            <Box>
                                <CardMedia
                                    // sx={{ width: { md: '20%', xs: '50%' } }}
                                    component={'img'}
                                    style={img}
                                    src={logo}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <PathPages />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <Information />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <ContactData />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Divider sx={{ backgroundColor: '#FFF', borderWidth: '1px' }} />
                            <DownFooter />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </div>
    )
}
const img = {

    borderRadius: '12px',
    backgroundSize: 'cover',
    // boxShadow: ' 0px 0px 12px 0px rgba(0, 0, 0, 0.14)',
    objectFit: 'fill',
    margin: 'auto'
}
export default Footer
