import React from 'react'
import Header2 from '../components/Header/Header2'
import { Box, Container, Grid, Typography } from '@mui/material'
// import path from './../assets/AboutUs/aboutUs.png'
import Card from '../components/ourService/Card'
import Title from '../components/glopal/Title'
import D4 from './../assets/Columns ACP_ Awnings/001.jpg'
import D6 from './../assets/Car Vinyl/003.jpg'
import D7 from './../assets/Car Vinyl/004.jpg'
import D5 from './../assets/decor/002.jpg'
import { useTranslation } from 'react-i18next'
const About = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Header2 title={t("linksPages.About")} desc={t("ourPartners.descP")} />
            <Container sx={{ padding: '50px' }}>
                <Box sx={{ padding: '25px 0' }}>
                    <Typography>
                        {t("aboutUs.desc")}
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={3} xs={12} >
                        <Card path={D4} />
                    </Grid>
                    <Grid item md={3} xs={12} >
                        <Card path={D5} />
                    </Grid>
                    <Grid item md={3} xs={12} >
                        <Card path={D6} />
                    </Grid>
                    <Grid item md={3} xs={12} >
                        <Card path={D7} />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Box>
                        <Title title={t("ourMission.Mission")} />
                        <Typography>{t("ourMission.descM")}</Typography>
                    </Box>
                    <Box>
                        <Title title={t("ourVision.Vision")} />
                        <Typography>
                            {t("ourVision.descV")}
                        </Typography>
                    </Box>
                    <Box>
                        <Title title={t("ourPartners.partners")} />
                        <Typography>
                            {t("ourPartners.descP")}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </div>
    )
}

export default About
